<template>
  <div class="steps-box juzhong">
    <i :class="currentStepsClass(1)"></i>
    <span :class="currentStepsClass(1)" @click="changeCurrentSteps(1)">1 <b>输入手机号码</b></span>
    <i :class="currentStepsClass(1, 2)"></i>
    <span :class="currentStepsClass(2)" @click="changeCurrentSteps(2)">2 <b> 身份验证</b></span>
    <i :class="currentStepsClass(2, 3)"></i>
    <span :class="currentStepsClass(3)" @click="changeCurrentSteps(3)">3 <b>设置新密码</b></span>
    <i :class="currentStepsClass(3)"></i>
  </div>
</template>

<script>
export default {
  name: 'steps',
  props: ['currentSteps'],
  methods: {
    currentStepsClass(val1, val2) {
      return this.currentSteps == val1 || this.currentSteps == val2 ? 'active' : ''
    },
    changeCurrentSteps(val) {
      this.$emit('changeCurrentSteps', val)
    }
  }
}
</script>

<style lang="scss" scoped>
// @text-color: #37c2bc;
@import '../css/baseTheme.scss';
$circlr-r: 60px;
$barwidth: 192px;
$barHeight: 6px;
.steps-box {
  display: flex;
  height: 200px;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 20px;

  span {
    display: block;
    width: $circlr-r;
    height: $circlr-r;
    margin: 0 20px;
    line-height: $circlr-r;
    border-radius: 50%;
    text-align: center;
    color: #ffffff;
    background: #cccccc;
    position: relative;

    font-size: 36px;
    b {
      color: #cccccc;
      position: absolute;
      width: 180px;
      bottom: -100px;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 26px;
      font-family: PingFang SC;
      // font-weight: bold;
      color: rgba(153, 153, 153, 1);
    }
  }

  i {
    width: $barwidth;
    height: $barHeight;
    display: inline-block;
    // border: 1px solid #cccccc;
    background: #cccccc;
    border-radius: 3px;
  }

  .active {
    background: $text-color;
    border-color: $text-color;
    b {
      color: $text-color;
    }
  }
}
</style>
