<template>
  <div class="content-box">
    <div class="mini-box" v-if="submitType !== 4">
      <component :is="childEnums[submitType]" :submitType.sync="submitType"></component>
    </div>
    <component :is="childEnums[submitType]" :submitType.sync="submitType" v-if="submitType == 4"></component>
  </div>
</template>

<script>
import login from '../components/login/index'
import forget from '../components/login/forget'
import register from '../components/login/register'
import verifyCode from '../components/login/verifyCode'

export default {
  components: {
    login,
    forget,
    register,
    verifyCode
  },
  data() {
    return {
      submitType: 1,
      childEnums: {
        1: 'login',
        2: 'verifyCode',
        3: 'register',
        4: 'forget'
      }
    }
  },
  created() {
    this.submitType = Number(this.$route.query.submitType) || 1
  }
}

/**
 *
 * 发送手机验证码 需要关联验证码类型和手机号   手机号+时间搓验证动态验证
 *
 * 划图验证层级最高 = 300
 *
 */
</script>

<style lang="scss" scoped>
.content-box {
  width: 100%;
  height: 100vh;
  position: relative;
  background: url(/img/bg.png);
}
.mini-box {
  width: 560px;
  height: auto;
  margin: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  padding: 40px 61px;
  background: #ffffff;
}
</style>
