<template>
  <div class="dialogcontainer juzhong">
    <div class="centerbox">
      <!-- <div class="text">
        请拖动滑块完成拼图
      </div> -->
      <div class="imgbox">
        <img class="bigImage" :src="bigImage" alt="ss" />
        <img class="smallImage" :style="{top: `${yHeight}px`, left: `${xwidth}px`}" :src="smallImage" alt="ss" />
      </div>
      <div class="drag" @mousemove="mousemove($event)" @mousedown="startPosition($event)">
        拖动滑块完成拼图
        <!-- <div class="bg"></div> -->
        <div class="btn" @mousedown="mousedown($event)" :style="{left: xwidth + 'px'}" @mouseup="mouseup($event)">
          |||
        </div>
      </div>
      <div>
        <p class="footer">
          <img src="/img/关闭.png" class="fll" alt="" @click="$emit('update:showSliderImgValidate', false)" />
          <img style="margin-left:30px;height:23px" src="/img/刷新.png" class="fll" alt="" @click="resetPicAgain" />
          <img src="/img/wenhao-2.png" class="flr" alt="" />
        </p>
        <!-- <span class="iconfont icon-problem fll"></span>
        <span class="iconfont icon-cancel flr" @click="$emit('update:showSliderImgValidate', false)"></span>
        <span class="iconfont icon-shuaxin flr" @click="resetPicAgain"></span> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'sliderImgValidate',
  props: ['yHeight', 'xwidth', 'bigImage', 'smallImage', 'showSliderImgValidate'],
  data() {
    return {
      canStartMove: false,
      screenX: 0
    }
  },
  methods: {
    startPosition(e) {},
    mousedown(e) {
      this.canStartMove = true
      this.screenX = e.screenX
    },
    mousemove(e) {
      if (!this.canStartMove) return
      let xwidth
      xwidth = e.screenX - this.screenX
      if (xwidth > 0) {
        this.$emit('changeImgPosition', xwidth)
      }
    },
    mouseup(e) {
      this.canStartMove = false
      this.$emit('changeImgPositionEnd')
    },
    resetPicAgain() {
      this.$emit('resetPicAgain')
    }
  }
}

/**
 *
 * 目前只实现一次的按下拖动时间，第二次的不支持
 *
 */
</script>

<style lang="scss" scoped>
$drag-height: 50px;
$button-color: #7cd01f;
$button-background: #ffffff;

.dialogcontainer {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  .centerbox {
    background: #ffffff;
    padding: 30px 30px;
    border-radius: 10px;
    .text {
      height: 50px;
      line-height: 50px;
      font-size: 28px;
      font-family: PingFang SC;
      color: rgba(0, 0, 0, 1);
    }
  }
  .footer {
    margin-top: 50px;
    height: 50px;
    line-height: 50px;
    padding-top: 15px;
    span {
      font-size: 25px;
    }
    .icon-shuaxin {
      font-size: 25px;
    }
    .icon-cancel {
      font-size: 30px;
      transform: translate(0, -10%);
    }
    img {
      height: 25px;
    }
  }
}
.imgbox {
  position: relative;
  left: 0;
  top: 0;
}
.bigImage {
  border-radius: 5px;
}
.smallImage {
  position: absolute;
  left: 0;
  // border: 1px solid #ffffff;
}

.drag {
  width: 100%;
  height: $drag-height;
  line-height: $drag-height;
  background-color: #ededed;
  position: relative;
  top: 20px;
  margin: 0 auto;
  border-radius: 25px;
  text-align: center;

  font-size: 21px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #00055c;
  line-height: 36px;
  line-height: 50px;

  .bg {
    width: 40px;
    height: 100%;
    position: absolute;
    // background-color: $button-background;
  }

  .btn {
    width: 80px;
    height: 80px;
    line-height: 80px;
    border-radius: 40px;
    top: -15px;
    position: absolute;
    border: 1px solid #ccc;
    cursor: move;
    font-family: '宋体';
    text-align: center;
    background-color: $button-background;
    user-select: none;
    color: $button-color;
    font-weight: bold;
    font-size: 30px;
  }
}
</style>
