<template>
  <div class="contentBox">
    <headerTap :submitType="submitType" v-on="$listeners" @loginImmediately="loginImmediately">
      <el-form :model="params" :rules="rules" ref="ruleForm" status-icon>
        <el-form-item prop="phone">
          <el-input
            type="text"
            placeholder="手机号"
            v-model="params.phone"
            @keyup.enter.native.native="loginImmediately"
            clearable
            icon
          />
        </el-form-item>
        <el-form-item style=" position: relative;">
          <el-input type="verifyCode" v-model="params.verifyCode" placeholder="短信验证码" icon class="send-input">
          </el-input>
          <el-button
            :disabled="canSendVerifyCode"
            @click="beforeGetVerifyCode"
            type="text"
            style=" color:#7cddd8;  font-size: 18px; position: absolute;right: 20px;bottom: 0px;"
            v-loading="showSliderImgValidate && !(showSliderImgValidate && params.smallImage && params.bigImage)"
          >
            {{ disCountNumber == '发送验证码' ? disCountNumber : `${disCountNumber}s之后重新发送` }}</el-button
          >
        </el-form-item>
      </el-form>
    </headerTap>
    <sliderImgValidate
      v-if="showSliderImgValidate && params.smallImage && params.bigImage"
      :yHeight="params.yHeight"
      :xwidth.sync="params.xwidth"
      :bigImage="params.bigImage"
      :smallImage="params.smallImage"
      :showSliderImgValidate.sync="showSliderImgValidate"
      @changeImgPosition="changeImgPosition"
      @changeImgPositionEnd="changeImgPositionEnd"
      @resetPicAgain="getSliderImgValidate"
    />
  </div>
</template>

<script>
import md5 from 'blueimp-md5'
import {
  getVerifyCodeApi,
  registerApi,
  loginByPwdApi,
  loginByCodeApi,
  findPwdApi,
  slipPicBigApi,
  slipPicSmallApi,
  checkSlipPicSmallApi,
  getUserInfoApi
} from '@/api/index'
import steps from '@/components/steps'
import sliderImgValidate from '@/components/sliderValidate'
import {mapMutations} from 'vuex'

import headerTap from './component/headerTap'

export default {
  components: {
    sliderImgValidate,
    headerTap
  },
  props: ['submitType'],

  watch: {},
  data() {
    const that = this
    const phone = (rule, value, callback) => {
      let regs = /^((13[0-9])|(14[0-9])|(15[0-9])|(16[0-9])|(17[0-9])|(18[0-9])|(19[0-9]))\d{8}$/
      if (!value) {
        return callback(new Error('请输入手机号'))
      }
      setTimeout(() => {
        if (!regs.test(value)) {
          callback(new Error('请输入正确的手机号'))
        } else {
          callback()
        }
      }, 1000)
    }
    const verifyCode = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入您收到的手机验证码'))
      } else if (value.length !== 6) {
        callback(new Error('请输入您收到的6位数字的手机验证码'))
      } else {
        callback()
      }
    }
    return {
      disCountNumber: '发送验证码',
      timer: null,
      currentSteps: 1, // 找回密码的步数
      showSliderImgValidate: false, //是否展示滑块验证
      needResetSlideBar: false, //次数超上限时候需要刷新
      params: {
        phone: '',
        verifyCode: '',
        password: '',
        passwordCheck: '',
        yHeight: '0',
        xwidth: '0', //滑块的位置坐标
        bigImage: '',
        smallImage: ''
      },

      rules: {
        phone: [{validator: phone, trigger: 'blur'}],
        verifyCode: [{validator: verifyCode, trigger: 'blur'}]
      }
    }
  },
  mounted() {
    // this.getSliderImgValidate()
  },
  computed: {
    ...mapMutations(['ChangeUserInfo']),
    disabled() {
      let flag
      if (!!this.params.phone && this.params.phone.length == 11) {
        if (this.submitType == 1 && !!this.params.password) {
          return false
        }
        if (this.submitType == 2 && !!this.params.verifyCode && this.params.verifyCode.length == 6) {
          return false
        }
        if (
          (this.submitType == 3 || this.submitType == 4) &&
          !!this.params.password &&
          this.params.password == this.params.passwordCheck &&
          this.params.verifyCode &&
          this.params.verifyCode.length == 6
        ) {
          return false
        }
      }
      return true
    },
    canSendVerifyCode() {
      const phone = this.params.phone
      return phone.length == 11 && !this.timer ? false : true
    },
    biz() {
      // 0-注册获取手机验证码，1-验证码登录获取手机验证码，2-找回密码获取手机验证码，3-登录密码次数超限
      // submitType   1密码登录 2验证码登录 3注册新用户 4 找回密码
      const biz = this.needResetSlideBar ? '0' : this.submitType
      const bizType = {0: '3', 1: '3', 2: '1', 3: '0', 4: '2'}
      return bizType[biz]
    }
  },
  methods: {
    // 获取验证码人机校验
    beforeGetVerifyCode() {
      if (this.params.phone) {
        this.showSliderImgValidate = true
        this.getSliderImgValidate()
      } else {
        this.$dialog.alert('请输入手机号')
      }
    },
    // 获取图片验证划图图片链接
    getSliderImgValidate() {
      const params = {}
      this.showSliderImgValidate = true
      params.phone = this.params.phone
      // biz 0-注册获取手机验证码，1-验证码登录获取手机验证码，2-找回密码获取手机验证码，3-登录密码次数超限
      params.biz = this.biz // 申请类型：1-登录密码次数超限；2-获取验证码
      // slipPicBigApi(params).then(res => {
      //   const params = {
      //     ...this.params,
      //     ...res.data
      //   }
      //   this.$set(this, 'params', params)
      // })
      slipPicSmallApi(params).then(res => {
        const params = {
          ...this.params,
          ...(res?.data || {})
        }
        this.$set(this, 'params', params)
      })
    },
    // 修改滑块的水平坐标最终的值
    changeImgPosition(val) {
      this.params.xwidth = val
    },
    // 验证图片位置是否正确
    changeImgPositionEnd() {
      this.$nextTick(() => {
        const params = {}
        params.biz = this.biz
        params.phone = this.params.phone
        params.xwidth = this.params.xwidth || '0'
        // console.log(params.xwidth)
        this.showSliderImgValidate = false
        checkSlipPicSmallApi(params)
          .then(res => {
            if (res.msg == 'success') {
              // this.$dialog.alert('<span class="iconfont icon-selection" style="font-size:100px;color:green"></span>', {
              //   btns: ['<span style="color:#000000">验证通过</span>'],
              //   callback: [
              //     () => {
              //       this.params.xwidth = 0
              //       this.submitType !== 1 && this.getVerifyCode() // 登录密码次数超限制时候不需要调取验证码
              //     }
              //   ]
              // })
              this.$message('验证通过')
              this.params.xwidth = 0
              this.submitType !== 1 && this.getVerifyCode() // 登录密码次数超限制时候不需要调取验证码
            } else {
              // this.params.xwidth = 0
              this.getSliderImgValidate()
            }
          })
          .catch(err => {
            // this.params.xwidth = 0
            this.getSliderImgValidate()
          })
          .finally(() => {
            const {yHeight, xwidth, bigImage, smallImage} = this.$options.data().params
            this.params = {...this.params, yHeight, xwidth, bigImage, smallImage}
          })
      })
    },
    // 获取验证码
    getVerifyCode() {
      // biz string: 0 注册  1 验证码登录  2 找回密码
      // submitType 1密码登录 2验证码登录 3注册新用户 4 找回密码
      let biz = this.biz
      const phone = this.params.phone
      getVerifyCodeApi({phone, biz})
        .then(res => {
          this.disCount()
        })
        .catch(err => {
          this.disCount()
        })
    },
    // 倒计时
    disCount() {
      this.disCountNumber = '60'
      this.timer = setInterval(() => {
        if (this.disCountNumber > 0) {
          this.disCountNumber--
        } else {
          this.disCountNumber = '发送验证码'
          this.timer = null
        }
      }, 1000)
      // const timerkey = `${this.phone}-${this.submitType}`
      // this.timer[timerkey] = setInterval(() => {
      //   if (this.disCountNumber > 0) {
      //     this.disCountNumber--
      //   } else {
      //     this.disCountNumber = '发送验证码'
      //     this.timer[timerkey] = null
      //     this.timer.delete(timerkey)
      //     console.log(this.timer)
      //   }
      // }, 1000)
    },

    // 切换修改登录注册找回密码的类型
    changesubmitType(type) {
      Object.assign(this.$data, this.$options.data())
      this.submitType = type
    },
    // 找回密码步数
    changeCurrentSteps(val) {
      this.currentSteps = val
    },

    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          // console.log('Received values of form: ', values)
        }
      })
    },
    // 取消找回密码
    cancelFindPassword() {
      if (this.$route.query.submitType) {
        this.$router.go(-1)
        return
      }
      this.currentSteps = 1
      // this.submitType = 1
      this.resetAllParams()
    },
    resetAllParams() {
      this.submitType = 1
      Object.assign(this.$data, this.$options.data())
    },
    // 登录注册
    loginImmediately() {
      if ([1, 2, 3].includes(this.submitType)) {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            this.login()
          } else {
            console.log('error submit!!')
            return false
          }
        })
      } else {
        this.$refs.ruleFormPassword.validate(valid => {
          if (valid) {
            this.login()
          } else {
            console.log('error submit!!')
            return false
          }
        })
      }
    },
    login() {
      const that = this
      const {password, verifyCode, phone, xwidth} = this.params
      switch (this.submitType) {
        case 1: //
          loginByPwdApi({phone, password: md5(password), xwidth}).then(res => {
            // console.log('%c 密码登录....', 'color: red; font-style: italic', JSON.stringify(res, null, 2))
            if (res.msg == 'success') {
              this.getUserInfo(res.data)
            } else {
              // this.$dialog.alert(res.msg, '..')
              // console.log('%c res....', 'color: red; font-style: italic', JSON.stringify(res, null, 2))
              if (res.code === 200001) {
                this.needResetSlideBar = true
                this.beforeGetVerifyCode()
              } else {
                this.$dialog.toast(res.data || '登录失败，请重试')
              }
            }
          })
          break
        case 2:
          loginByCodeApi({phone, verifyCode}).then(res => {
            console.log('%c 验证码登录....', 'color: red; font-style: italic', JSON.stringify(res, null, 2))
            if (res.msg == 'success') {
              this.getUserInfo(res.data)
            } else {
              this.$dialog.toast(res.msg || '登录失败，请重试')
            }
          })
          break
        case 3: //
          registerApi({phoneNumber: phone, password: md5(password), verifyCode}).then(res => {
            if (res.msg == 'success') {
              this.submitType = 1
              this.getUserInfo(res.data)
            } else {
              this.$dialog.toast(res.msg || '注册失败，请重试')
            }
          })
          break
        case 4:
          findPwdApi({phone, password: md5(password), verifyCode}).then(res => {
            console.log('%c 找回密码....', 'color: red; font-style: italic', JSON.stringify(res, null, 2))
            if (res.msg == 'success') {
              // this.$message('密码重置成功')
              // this.$confirm('确认关闭？')
              //   .then(_ => {
              //     this.submitType = 1
              //     this.resetAllParams()
              //   })
              //   .catch(_ => {})
              this.$dialog.alert('密码重置成功', {
                btns: ['确认'],
                callback: [
                  () => {
                    this.submitType = 1
                    this.getUserInfo(res.data)
                  }
                ]
              })
            } else {
              if (res.data) this.$dialog.toast(res.data || '注册失败，请重试')
            }
          })
          break
      }
    },
    getUserInfo(str) {
      localStorage.setItem('TOKEN', str)

      getUserInfoApi({phone: this.params.phone}).then(res => {
        // console.log('%c 获取用户信息....', 'color: red; font-style: italic', JSON.stringify(res, null, 2))
        localStorage.setItem('UserInfo', JSON.stringify(res.data))
        this.$store.commit('ChangeUserInfo', res.data)
        this.$router.push('/home')
      })
    },
    nextStep() {
      if (this.currentSteps == 1) {
        this.$refs.ruleFormPhone.validate(valid => {
          if (valid) {
            this.currentSteps += 1
          } else {
            console.log('error submit!!')
            return false
          }
        })
      }

      if (this.currentSteps == 2) {
        this.$refs.ruleFormVerifyCode.validate(valid => {
          if (valid) {
            this.currentSteps += 1
          } else {
            console.log('error submit!!')
            return false
          }
        })
      }
    }
  }
}

/**
 *
 * 发送手机验证码 需要关联验证码类型和手机号   手机号+时间搓验证动态验证
 *
 * 划图验证层级最高 = 300
 *
 */
</script>

<style scoped lang="scss">
.contentBox {
  /deep/ .el-input__inner {
    height: 45px;
    margin-top: 15px;
    // margin-bottom: 30px;
  }
  /deep/ .el-input__suffix {
    top: 10px;
  }
}
</style>
